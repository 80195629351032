/**
 * @description: 系统信息页面接口列表
 */

import request from './request'

// 获取所有终端分组
export function get_version () {
  return request({
    url: '/sys/sys_info',
    method: 'get'
  })
}

// 获取所有终端分组
export function export_version () {
  return request({
    url: '/sys/download_sys_info',
    method: 'get',
    responseType: 'blob'
  })
}
