<template>
  <div>
    <TablePage>
      <template #toolbar>
        <el-button
          type="primary"
          @click="exportFile"
        >
          {{ $t("common.export") }}
        </el-button>
      </template>
      <div class="box">
        <span>{{ $t('systemMsg.imanagerV') }}:&nbsp;</span>
        <span>{{ versionObj.sys_version }}</span>
      </div>
      <div class="box">
        <span>{{ $t('systemMsg.iMSV') }}:&nbsp;</span>
        <div
          v-for="(version,index) in versionObj.ims_version_list"
          :key="version"
        >
          <span>{{ version }}</span>
          <span v-if="index < versionObj.ims_version_list.length - 1">&nbsp;/&nbsp;</span>
        </div>
      </div>
      <div class="box">
        <span>{{ $t('systemMsg.pushV') }}:&nbsp;</span>
        <div
          v-for="(version,index) in versionObj.pusher_version_list"
          :key="version"
        >
          <span>{{ version }}</span>
          <span v-if="index < versionObj.pusher_version_list.length - 1">&nbsp;/&nbsp;</span>
        </div>
      </div>
      <div class="box">
        <span>{{ $t('systemMsg.pullV') }}:&nbsp;</span>
        <div
          v-for="(version,index) in versionObj.player_version_list"
          :key="version"
        >
          <span>{{ version }}</span>
          <span v-if="index < versionObj.player_version_list.length - 1">&nbsp;/&nbsp;</span>
        </div>
      </div>
      <div class="box">
        <span>{{ $t('systemMsg.currentProject') }}:&nbsp;</span>
        <div
          v-for="(version,index) in versionObj.project_list"
          :key="version"
        >
          <span>{{ version }}</span>
          <span v-if="index < versionObj.project_list.length - 1">&nbsp;/&nbsp;</span>
        </div>
      </div>
      <div class="box">
        <span>{{ $t('systemMsg.currentScene') }}:&nbsp;</span>
        <div
          v-for="(version,index) in versionObj.se_list"
          :key="version"
        >
          <span>{{ version }}</span>
          <span v-if="index < versionObj.se_list.length - 1">&nbsp;/&nbsp;</span>
        </div>
      </div>
    </TablePage>
  </div>
</template>

<script>
import TablePage from '@/components/TablePage.vue'
import { get_version, export_version } from '@/api/system.js'
import { blobDodnload } from '@/common/util.js'
export default {
  components: {
    TablePage
  },
  data () {
    return {
      versionObj: {}
    }
  },
  created () {
    this.init()
  },
  methods: {
    async exportFile () {
      const res = await export_version()
      blobDodnload(res)
    },
    async getVersion () {
      const res = await get_version()
      if (res.data.code === 0) {
        this.versionObj = res.data.data.sys_info
      }
    },
    init () {
      this.getVersion()
    }
  }
}
</script>

<style lang='scss' scoped>
.box {
  height: 40px;
  line-height: 40px;
  color: #606266;
  display: flex;
}
</style>
